import React from "react";
import { Route, Routes } from "react-router-dom";
import DefaultLayout from "../layouts/Default/index";

// layouts
// import NonAuthLayout from "../layouts/NonAuth/index";
// import { AuthProtected } from "./AuthProtected";

// import { privateRoutes, publicRoutes } from "./allRoutes";
import { privateRoutes } from "./allRoutes";

const Index = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        {/* <Route>
          {publicRoutes.map((route: any, idx: number) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
            />
          ))}
        </Route> */}

        <Route>
          {privateRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<DefaultLayout>{route.component}</DefaultLayout>}
              key={idx}
              // exact={true}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
