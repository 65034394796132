import avatar1 from "../assets/images/users/avatar-1.jpg";
import avatar10 from "../assets/images/users/avatar-10.jpg";
import avatar14 from "../assets/images/users/avatar-14.jpg";
import avatar15 from "../assets/images/users/avatar-15.jpg";
import avatar2 from "../assets/images/users/avatar-2.jpg";
import avatar20 from "../assets/images/users/avatar-20.jpg";
import avatar21 from "../assets/images/users/avatar-21.jpg";
import avatar22 from "../assets/images/users/avatar-22.jpg";
import avatar23 from "../assets/images/users/avatar-23.jpg";
import avatar24 from "../assets/images/users/avatar-24.jpg";
import avatar3 from "../assets/images/users/avatar-3.jpg";
import avatar4 from "../assets/images/users/avatar-4.jpg";
import avatar5 from "../assets/images/users/avatar-5.jpg";
import avatar6 from "../assets/images/users/avatar-6.jpg";
import avatar7 from "../assets/images/users/avatar-7.jpg";
import avatar8 from "../assets/images/users/avatar-8.jpg";
import avatar9 from "../assets/images/users/avatar-9.jpg";

export interface CallItem {
  callId: string | number;
  firstName: string;
  lastName: string;
  profileImage?: string;
  callDuration: string;
  isIncomming: boolean;
  callDate: string;
  isGrouped: boolean;
  hasVideoCall: boolean;
  peoplesAvailableOnCall: number;
  peoples?: Array<any>;
}
const calls: CallItem[] = [
  {
    callId: 0,
    firstName: "Ansem",
    lastName: "❤️",
    profileImage: avatar20,
    callDuration: "5:37",
    isIncomming: true,
    callDate: "2024-03-05T06:21:22",
    isGrouped: false,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 1,
    firstName: "Elon",
    lastName: "Musk",
    profileImage: avatar8,
    callDuration: "5:24",
    isIncomming: true,
    callDate: "2024-03-17T10:22:03",
    isGrouped: false,
    hasVideoCall: false,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 2,
    firstName: "Michael",
    lastName: "Saylor",
    profileImage: avatar22,
    callDuration: "2:43",
    isIncomming: false,
    callDate: "2023-11-27T04:11:34",
    isGrouped: false,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 3,
    firstName: "Billy",
    lastName: "Mays",
    profileImage: avatar24,
    callDuration: "2:41",
    isIncomming: false,
    callDate: "2024-01-29T09:52:06",
    isGrouped: true,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 4,
    firstName: "Ansem",
    lastName: "❤️",
    profileImage: avatar20,
    callDuration: "1:57",
    isIncomming: false,
    callDate: "2024-02-23T06:05:34",
    isGrouped: true,
    hasVideoCall: false,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 5,
    firstName: "Donald",
    lastName: "Trump",
    profileImage: avatar21,
    callDuration: "1:24",
    isIncomming: true,
    callDate: "2023-10-28T09:42:19",
    isGrouped: false,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 6,
    firstName: "Paul",
    lastName: "Wilson",
    profileImage: avatar2,
    callDuration: "1:22",
    isIncomming: true,
    callDate: "2023-09-19T07:33:53",
    isGrouped: false,
    hasVideoCall: false,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 7,
    firstName: "Nathan",
    lastName: "Gemmell",
    profileImage: avatar15,
    callDuration: "4:52",
    isIncomming: true,
    callDate: "2023-10-07T02:04:57",
    isGrouped: false,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 8,
    firstName: "Ansem",
    lastName: "❤️",
    profileImage: avatar20,
    callDuration: "4:24",
    isIncomming: true,
    callDate: "2024-01-16T01:31:46",
    isGrouped: true,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 9,
    firstName: "Ansem",
    lastName: "❤️",
    profileImage: avatar20,
    callDuration: "4:13",
    isIncomming: false,
    callDate: "2024-03-12T06:47:49",
    isGrouped: false,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 10,
    firstName: "Ansem",
    lastName: "❤️",
    profileImage: avatar20,
    callDuration: "2:12",
    isIncomming: true,
    callDate: "2023-09-11T09:09:00",
    isGrouped: false,
    hasVideoCall: false,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 11,
    firstName: "Andrew",
    lastName: "Sorkin",
    profileImage: avatar23,
    callDuration: "5:33",
    isIncomming: true,
    callDate: "2024-02-13T10:53:36",
    isGrouped: false,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 12,
    firstName: "Kellen",
    lastName: "Carter",
    profileImage: avatar9,
    callDuration: "2:55",
    isIncomming: true,
    callDate: "2024-04-22T07:35:32",
    isGrouped: true,
    hasVideoCall: false,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 13,
    firstName: "Beth",
    lastName: "Bilyeu",
    profileImage: avatar3,
    callDuration: "2:52",
    isIncomming: false,
    callDate: "2023-07-22T11:03:38",
    isGrouped: true,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 14,
    firstName: "Ben",
    lastName: "Boxer",
    profileImage: avatar5,
    callDuration: "3:13",
    isIncomming: false,
    callDate: "2024-02-14T10:54:34",
    isGrouped: false,
    hasVideoCall: false,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 15,
    firstName: "Frank",
    lastName: "Downing",
    profileImage: avatar14,
    callDuration: "3:44",
    isIncomming: true,
    callDate: "2023-11-18T12:29:36",
    isGrouped: true,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 16,
    firstName: "Jacquelyn",
    lastName: "Norton",
    callDuration: "3:2",
    isIncomming: true,
    callDate: "2023-07-02T09:37:16",
    isGrouped: true,
    hasVideoCall: false,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 17,
    firstName: "Beulah",
    lastName: "Mcleod",
    profileImage: avatar9,
    callDuration: "3:18",
    isIncomming: true,
    callDate: "2023-09-01T07:55:38",
    isGrouped: true,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 18,
    firstName: "Alexandre",
    lastName: "Bini",
    profileImage: avatar10,
    callDuration: "2:20",
    isIncomming: false,
    callDate: "2024-04-06T05:00:05",
    isGrouped: false,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 19,
    firstName: "Ansem",
    lastName: "❤️",
    profileImage: avatar20,
    callDuration: "25:16",
    isIncomming: true,
    callDate: "2024-03-05T06:21:22",
    isGrouped: false,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
];

export { calls };
