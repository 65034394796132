import gif1 from "../assets/images/gifs/1.gif";
import gif2 from "../assets/images/gifs/2.gif";
import gif3 from "../assets/images/gifs/3.gif";
import gif4 from "../assets/images/gifs/4.gif";
import gif5 from "../assets/images/gifs/5.gif";
import gif6 from "../assets/images/gifs/6.gif";
import gif7 from "../assets/images/gifs/7.gif";
import gif8 from "../assets/images/gifs/8.gif";
import gif9 from "../assets/images/gifs/9.gif";
import gif11 from "../assets/images/gifs/extra11.gif";
import gif12 from "../assets/images/gifs/extra12.gif";
import img1 from "../assets/images/static/img1.jpg";
import img2 from "../assets/images/static/img2.jpg";
import img3 from "../assets/images/static/img3.jpg";
import img4 from "../assets/images/static/img4.jpg";
import img5 from "../assets/images/static/img5.jpg";
import img6 from "../assets/images/static/img6.jpg";
import img7 from "../assets/images/static/img7.jpg";
import img8 from "../assets/images/static/img8.jpg";
import sticker1 from "../assets/images/stickers/bullish.png";
import sticker2 from "../assets/images/stickers/caty-army.png";
import sticker3 from "../assets/images/stickers/caty.png";
import sticker4 from "../assets/images/stickers/diamond-hands.png";
import sticker5 from "../assets/images/stickers/eat-the-dip.png";
import sticker6 from "../assets/images/stickers/green-candles-only.png";
import sticker7 from "../assets/images/stickers/pumpit.png";
import sticker8 from "../assets/images/stickers/to-the-moon.png";
import sticker9 from "../assets/images/stickers/top-project.png";
import sticker10 from "../assets/images/stickers/welcome.png";
import sticker11 from "../assets/images/stickers/wen-lambo.png";
import vid1 from "../assets/videos/vid1.mp4";
import { contacts, ContactTypes } from "./contacts";
import { myData } from "./myProfile";

export interface AttachmentTypes {
  id: number;
  name: string;
  downloadLink: string;
  desc: string;
}

export interface ImageTypes {
  id: number;
  downloadLink: string;
}
export interface MessagesTypes {
  mId: number;
  text?: string;
  time: string;
  meta: {
    receiver: string | number;
    sender: string | number;
    userData?: ContactTypes;
    sent: boolean;
    received: boolean;
    read: boolean;
    isForwarded?: boolean;
  };
  attachments?: AttachmentTypes[];
  image?: ImageTypes[];
  newimage?: ImageTypes[];
  replyOf?: MessagesTypes;
}
export interface ConversationTypes {
  conversationId: string | number;
  userId: string;
  isGroupConversation?: boolean;
  typingUser?: string | number;
  messages: MessagesTypes[];
}

export const myId = myData.uid;
let conversations: ConversationTypes[] = [
  {
    conversationId: 1,
    userId: "614ecab4ac946a9bdafa4e3b",
    typingUser: "614ecab4ac946a9bdafa4e3b",
    messages: [
      {
        mId: 1,
        text: "GM Cathie 😊! I hope you're ready for some exciting news. $CATY is gearing up to claw its way to the top of the memecoin market!",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ac946a9bdafa4e3b",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Hey trippy! That sounds intriguing. I'm all ears. What's the game plan for turning heads in the crypto space?",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4ac946a9bdafa4e3b",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "We're going all-in on community and hype. It's time to unleash a tidal wave of memes and viral content that'll make $CATY impossible to ignore.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ac946a9bdafa4e3b",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Memes can be a powerful currency in the digital world. How do we spark this meme renaissance for $CATY?",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4ac946a9bdafa4e3b",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "We're kicking things off with a 'Meme Bounty'—a call-to-action for our community to create and share $CATY memes that'll put a smile on every face. The most viral creators will be hailed as our 'Meme Moguls.'",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ac946a9bdafa4e3b",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Check out some of these memes from the community so far!",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ac946a9bdafa4e3b",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ac946a9bdafa4e3b",
          sent: true,
          received: true,
          read: true,
        },
        image: [
          {
            id: 1,
            downloadLink: gif1,
          },
          {
            id: 2,
            downloadLink: gif2,
          },
          {
            id: 3,
            downloadLink: gif3,
          },
          {
            id: 4,
            downloadLink: gif4,
          },
          {
            id: 5,
            downloadLink: gif5,
          },
        ],
      },
      {
        mId: 8,
        text: "😂 These $CATY memes are more contagious than a cat's purr in a silent room! I haven't laughed this hard since I tried explaining blockchain to my grandma. The internet better brace itself; we've got the humor equivalent of catnip here! #CanHazMoreCATY?",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4ac946a9bdafa4e3b",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 2,
    userId: "614ecab41f60c4fdffe639c8",
    typingUser: "614ecab41f60c4fdffe639c8",
    messages: [
      {
        mId: 1,
        text: "Morning Beth, I've double-booked myself Sunday morning. Charity event and church. I need divine intervention or your scheduling wizardry—whichever comes first.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4ac946a9bdafa4e3b",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Divine intervention is above my pay grade, Cathie, but let me work some scheduling magic. How about we teleport you? Just kidding, I'll shuffle a few things around.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ac946a9bdafa4e3b",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "You always have the answers, Beth.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4ac946a9bdafa4e3b",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "I try my best! I'll adjust your schedule. Oh, and speaking of church, weren't we supposed to help with the fundraiser next week?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ac946a9bdafa4e3b",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "Yes, we were. It's going to be a busy week. We might have to pray for a little extra energy.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4ac946a9bdafa4e3b",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "I think we'll manage just fine. I've got the coffee on standby.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ac946a9bdafa4e3b",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "What would I do without you and coffee?",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab4ac946a9bdafa4e3b",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 3,
    userId: "614ecab400931b0aba9d0d76",
    typingUser: "614ecab400931b0aba9d0d76",
    messages: [
      {
        mId: 1,
        text: "Morning, Kyle! Have you seen the $CATY index fund numbers? We've got great news for the board.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab400931b0aba9d0d76",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Morning, Cathie. I have, and the numbers look fantastic. I'll get started on the report. Any particular focus for the board?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab400931b0aba9d0d76",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "Let's emphasize the innovation and risk strategies that got us here. We've outpaced the market, and the board will want to know how.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab400931b0aba9d0d76",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Will do. I'll tie in our active management approach and highlight the strategic calls that made the difference.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab400931b0aba9d0d76",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "Perfect. And ensure our SOX compliance shines through—we want them to see success was achieved with integrity.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab400931b0aba9d0d76",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Absolutely, integrity is just as important as our returns. I'll make sure that comes across.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab400931b0aba9d0d76",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "Thanks, Kyle. We're not just playing the game; we're changing it.",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab400931b0aba9d0d76",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 4,
    userId: "614ecab4c2a5e35f32f78659",
    typingUser: "614ecab4c2a5e35f32f78659",
    messages: [
      {
        mId: 1,
        text: "Ben, with $CATY's astronomical success, we need a campaign that's out of this world. What's your vision?",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4c2a5e35f32f78659",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Cathie, imagine this: a meme crusade that captures the thrill of $CATY's journey. We're not just riding the hype train; we're the conductors.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4c2a5e35f32f78659",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "Memes can go viral fast. Make sure they resonate with our brand and the excitement of our investors.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4c2a5e35f32f78659",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Think eye-catching, brain-sticking visuals with a tagline that screams 'to the moon'. Plus, a meme contest that gets our community's creative rockets firing!",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4c2a5e35f32f78659",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "That's the energy we need. Make it fun, make it smart, and let's launch $CATY across the social media universe.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4c2a5e35f32f78659",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Consider it done. We'll give 'going viral' a whole new meaning. $CATY won't just be a coin; it'll be a cultural phenomenon.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4c2a5e35f32f78659",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 5,
    userId: "614ecab4a4666b6ff5f7864f",
    typingUser: "614ecab4a4666b6ff5f7864f",
    messages: [
      {
        mId: 1,
        text: "Kellen, the growth we're seeing with $CATY is beyond what we projected. It's a time for celebration.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4a4666b6ff5f7864f",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "It's remarkable, Cathie. But while I'm thrilled, I can't shake off the compliance side of things. The faster we grow, the more I think about our regulatory framework.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4a4666b6ff5f7864f",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "I understand the pressure. Compliance is critical, especially with such success. How can we maintain this momentum while staying within the lines?",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4a4666b6ff5f7864f",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "We need to double down on diligence. I'm thinking of implementing an advanced compliance monitoring system. It's an investment, but it'll pay off by keeping us ahead of any potential issues.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4a4666b6ff5f7864f",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "Proactivity is key in compliance. Let's ensure our tech is as cutting-edge as our investments. I trust your judgment, Kellen.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4a4666b6ff5f7864f",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Thanks, Cathie. I'll start vetting providers immediately. I want to enjoy this success as much as everyone but not at the expense of our integrity or our investor's trust.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4a4666b6ff5f7864f",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "Absolutely, Kellen. Your expertise is invaluable, and I want you to know that compliance is as celebrated as performance here. We grow and protect our investors' interests together.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4a4666b6ff5f7864f",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 8,
        text: "Appreciate that, Cathie. I'll make sure our growth is on a solid foundation. Let's make $CATY the gold standard for compliance as well as performance.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4a4666b6ff5f7864f",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 5,
    userId: "614ecab4fd445a537e2bb2b5",
    typingUser: "614ecab4fd445a537e2bb2b5",
    messages: [
      {
        mId: 1,
        text: "$MEW is set to rocket – thesis checked, backed by heavy hitters. Market cap at 280K. Position size?",
        time: new Date().toISOString(),
        meta: {
          sender: "614ecab4fd445a537e2bb2b5",
          receiver: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "I'm in for 15 bands.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4fd445a537e2bb2b5",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "Done.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4fd445a537e2bb2b5",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 6,
    userId: "614ecab4ebb18e740e372e80",
    typingUser: "614ecab4ebb18e740e372e80",
    messages: [
      {
        mId: 1,
        text: "Elon, every time TSLA stock dips, I think it's just 'charging' up for another run. How's our favorite EV looking under the hood?",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab4ebb18e740e372e80",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Ha, nice one, Cathie! But let's not exhaust our conversation on stocks. I'm more curious about the $CATY index fund. Is it 'purring' along?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ebb18e740e372e80",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "Always redirecting the current, aren't we? TSLA's fundamentals are as solid as a Cybertruck's door. But since you asked, $CATY is more agile than a cat on a hot Tesla roof.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4ebb18e740e372e80",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Speaking of agility, I'm thinking of a new SpaceX training program—zero-gravity cat herding. Thoughts?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ebb18e740e372e80",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "Only if you're planning to livestream it for maximum shareholder enjoyment! But back on Earth, Elon, investors are 'revving' up for some TSLA insights.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4ebb18e740e372e80",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Well, you know what they say, curiosity built the Mars rover. As for TSLA, let's just say we're accelerating at ludicrous speed while staying on autopilot. Now, how are those $CATY 'meownagers' handling the fund?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ebb18e740e372e80",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "They're 'feline' fine about the growth prospects. But seriously, Elon, if TSLA were a cat, I'd say it's got nine lives and we've barely scratched the first.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4ebb18e740e372e80",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 8,
        text: "Love the enthusiasm, Cathie! But speaking of scratching, I gotta jet. We're testing scratch-resistant windows on the Starship. Catch you on the next shareholder call!",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4ebb18e740e372e80",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4ebb18e740e372e80",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
        image: [
          {
            id: 1,
            downloadLink: gif5,
          },
        ],
      },
    ],
  },
  {
    conversationId: 7,
    userId: "614ecab48c8cc404a0604fc4",
    typingUser: "614ecab48c8cc404a0604fc4",
    messages: [
      {
        mId: 1,
        text: "Michael, I've seen your tweets. It seems you've got quite the bearish view on our investment thesis. Care to share more on that, or are 280 characters the limit to your analysis?",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab48c8cc404a0604fc4",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Cathie, investing is about seeing the forest for the trees. Sometimes, that forest is on fire, and it seems like you're suggesting we warm ourselves by it. High valuations, tech reliance—these are real risks, not virtual ones.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab48c8cc404a0604fc4",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "Risks? Or opportunities you're not willing to seize? Our strategy is about innovation, about being on the right side of change. It's easy to predict doom—it's far braver to bet on progress.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab48c8cc404a0604fc4",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Brave bets are one thing, Cathie, but the numbers need to make sense. You're projecting a future that assumes everything goes right. What if it doesn't? What's your plan B?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab48c8cc404a0604fc4",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "Progress isn't linear, Michael, but it's inevitable. We adapt, just like the companies we invest in. It's not about having a plan B, it's about making sure plan A is robust enough to evolve.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab48c8cc404a0604fc4",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Evolution is a slow process, and the market doesn’t always wait. I respect your optimism, but I'm playing the long game with a different set of rules. It's not personal, it's probability.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab48c8cc404a0604fc4",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "Probability, or pessimism? You're entitled to your opinion, just as I am to mine. But let's make one thing clear—when it comes to innovation, I won't be told to 'just fuck off.'",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab48c8cc404a0604fc4",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 8,
        text: "A fiery response, Cathie. I'm not asking you to back down, just to acknowledge the heat. Remember, even Tesla's rockets generate a lot of fire before they take off.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab48c8cc404a0604fc4",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 8,
    userId: "614ecab43dbce45d6d996d9f",
    typingUser: "614ecab43dbce45d6d996d9f",
    messages: [
      {
        mId: 1,
        text: "Alex, I've been eagerly awaiting your update. The $CATY token is a significant step for us, and we need the contract details and the verified token listing page from DexScreener. How is that coming along?",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab43dbce45d6d996d9f",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Cathie, my apologies for the suspense. We're finalizing the last checks to ensure all the data aligns perfectly with DexScreener's standards. Accuracy is key, and we want $CATY's debut to be seamless.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab43dbce45d6d996d9f",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "I appreciate the diligence, Alex. It's crucial for our investors to have a smooth and transparent experience. When can we expect to have everything in place?",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab43dbce45d6d996d9f",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "The team is wrapping up the final audit, and if all goes well, I should be able to send the contract details and the direct link to the $CATY listing page by tomorrow. We're just as excited as you are to see this go live.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab43dbce45d6d996d9f",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "Perfect. Our investors are eagerly anticipating this, and your platform's integrity adds tremendous value. Let's ensure we communicate clearly on this; the market watches every move $CATY makes.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab43dbce45d6d996d9f",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Absolutely, Cathie. We'll ensure the listing reflects the innovation $CATY stands for. DexScreener is ready to support your leap into DeFi. You'll have the contract details on your desk first thing in the morning.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab43dbce45d6d996d9f",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: `Cathie, I'm glad to report that we've completed our due diligence on $CATY. You'll find the details below: <br /><br /><a href="https://dexscreener.com/solana/hyfhwvh598jgmfcjgf76nffwjndomgcohmkhterwb9ww" target="_blank">https://dexscreener.com/solana/hyfhwvh598jgmfcjgf76nffwjndomgcohmkhterwb9ww</a>`,
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab43dbce45d6d996d9f",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 9,
    userId: "614ecab44abffd0867521196",
    typingUser: "614ecab44abffd0867521196",
    messages: [
      {
        mId: 1,
        text: "Zion, your insights have been invaluable. I'm impressed with the depth of your research on these cat-themed tokens. How confident are you in the diversity of MEW, HOBBES, nubcat, Shark Cat, CET, and BAG for our $CATY index?",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab44abffd0867521196",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Cathie, I've been deep in the data, and I believe these tokens have a unique position within the ecosystem. They not only offer diversity but also tap into the community-driven momentum that's been a hallmark of successful crypto assets. But remember, this is still a high-risk play, even for a thematic index like $CATY.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab44abffd0867521196",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "Absolutely, the risk is clear. But so is the potential. We're not just investing in tokens; we're investing in the communities and the tech behind them. I trust your judgment, Zion, but we'll proceed with caution.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab44abffd0867521196",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "That's the right approach. The key will be timing and balance. We'll monitor the on-chain activity closely and adjust the index composition as the market evolves. And, of course, our collaboration remains between us—for now.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab44abffd0867521196",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "Of course, discretion is paramount. We can't afford to stir up speculative frenzy. The strength of $CATY will be its strategic composition, not its hype.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab44abffd0867521196",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Understood, Cathie. I'll keep the data flowing. Should I swing by later?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab44abffd0867521196",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "Tomorrow. Caitlin and Caroline are over tonight.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab44abffd0867521196",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 10,
    userId: "614ecab49b13ce36ad017914",
    typingUser: "614ecab49b13ce36ad017914",
    messages: [
      {
        mId: 1,
        text: "Mario, it’s great to have you on board with the $CATY project. Your track record speaks volumes, and we're looking forward to the energy you'll bring. What's the first thing on your agenda now that you're officially part of the team?",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab49b13ce36ad017914",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Cathie, thank you for the warm welcome. You know me, I'm all about action. I've got a comprehensive plan to turbocharge $CATY's presence. We're not just aiming for the moon; we're setting up a colony there. It starts with leveraging my network to create a buzz that gets everyone talking about $CATY.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab49b13ce36ad017914",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "I'm curious about the specifics of your plan, Mario. We share the ambition, but I want to ensure our strategies align with ARK's vision and compliance standards.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab49b13ce36ad017914",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Of course, Cathie. Compliance and vision are crucial. I'm thinking of a multi-platform marketing blitz, aggressive community engagement, strategic partnerships with key fintech players, and maybe even exploring some celebrity endorsements to make $CATY a household name.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab49b13ce36ad017914",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "Strategic partnerships and community engagement sound promising. However, we'll need to tread carefully with celebrity endorsements to maintain our credibility. What’s your take on integrating educational content to help potential investors understand $CATY's value proposition?.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab49b13ce36ad017914",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Education is power, and an informed community is a strong foundation for any project. I'm all in for creating digestible content that can not only educate but also excite our potential investors. We'll make $CATY synonymous with innovation and smart investing.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab49b13ce36ad017914",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "I'm glad we're on the same page, Mario. It's about striking a balance between aggressive growth and sustainable value creation. Let's draft a detailed roadmap with clear milestones, and we can take it from there.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab49b13ce36ad017914",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 8,
        text: "Absolutely, let's plot the course. With ARK's expertise and my growth hacking tactics, we're about to make some serious waves. Ready when you are to chart this journey to the stars.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab49b13ce36ad017914",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 11,
    userId: "614ecab4fec7ab1ffb2a497d",
    typingUser: "614ecab4fec7ab1ffb2a497d",
    messages: [
      {
        mId: 1,
        text: "Mr. President, it's always a pleasure to support a campaign that aligns with growth and prosperity. Your policies on tax reduction are essential for our economy, and I've backed this belief with my support.",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab4fec7ab1ffb2a497d",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Cathie, your support has been terrific, really terrific. People with your kind of success, they know what's good for America. You've built a financial empire; it's huge! And your support? It's yuge too! We're going to win big thanks to patriots like you.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4fec7ab1ffb2a497d",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "I appreciate that, Mr. President. Speaking of winning big, I want to introduce you to a project that's close to my heart — the $CATY index fund. It's a cutting-edge investment vehicle in the crypto space, and I believe it could be monumental for your financial portfolio.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4fec7ab1ffb2a497d",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Crypto, huh? I’ve heard a lot about it. A lot of people are talking. But Cathie, I have to tell you, I'm a real estate guy. Buildings, golf courses — you can see them, touch them. This crypto thing, it's like magic internet money. You can't even see it!",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4fec7ab1ffb2a497d",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "I understand the hesitation, but $CATY is part of an emerging asset class. It represents innovation, something your administration champions. What better way to stay ahead of the curve than with a strategic crypto investment?",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab4fec7ab1ffb2a497d",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Innovation is great, I love innovation. I was always the best at technology, believe me. But Cathie, I'm going to be the biggest winner in the real world, not in some virtual fantasy. Crypto, it could go to zero! It could go poof! And where does it go? Nobody knows. Maybe it goes to cyber space, maybe it goes to Mars with Elon. But it's not for me, I prefer winning with dollars, not bits and bytes.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab4fec7ab1ffb2a497d",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 12,
    userId: "61665bcb9a456823e282afa7",
    typingUser: contacts[0].id,
    isGroupConversation: true,
    messages: [
      {
        mId: 1,
        text: "Hey can I have the contract, dex and social links for $CATY here please?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: myId,
          userData: contacts[1],
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: `Sure Cathie, the CA:<br /><br />8mY8bif63v5vAHYaHPhpob71K4uJsDKXVx7h9h1XmJ6N`,
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: contacts[7].id,
          userData: contacts[7],
          sent: true,
          received: true,
          read: true,
        },
        image: [
          {
            id: 1,
            downloadLink: img8,
          },
        ],
      },
      {
        mId: 3,
        text: `<a href="https://solscan.io/token/8mY8bif63v5vAHYaHPhpob71K4uJsDKXVx7h9h1XmJ6N" target="_blank">Solscan</a> | <a href="https://www.dextools.io/app/en/solana/pair-explorer/HYFhWVH598jGMFCjGF76NfFwjndomgcohmKHTERWb9WW?t=1712232056399" target="_blank">Dextools</a> | <a href="https://dexscreener.com/solana/hyfhwvh598jgmfcjgf76nffwjndomgcohmkhterwb9ww" target="_blank">DexScreener</a><br /><br />TG: <a href="https://t.me/catywuudsol" target="_blank">@catywuudsol</a><br />Twitter: <a href="https://x.com/catywuudsol" target="_blank">@catywuudsol</a><br />Website: <a href="https://catywuud.lol" target="_blank">catywuud.lol</a>`,
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: contacts[7].id,
          userData: contacts[7],
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: `10M is FUD!!!`,
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: contacts[7].id,
          userData: contacts[7],
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 13,
    userId: "61665bcbc0f86d33164365c8",
    typingUser: contacts[0].id,
    isGroupConversation: true,
    messages: [
      {
        mId: 1,
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: contacts[7].id,
          userData: contacts[7],
          sent: true,
          received: true,
          read: true,
        },
        image: [
          {
            id: 1,
            downloadLink: gif1,
          },
          {
            id: 2,
            downloadLink: gif2,
          },
          {
            id: 3,
            downloadLink: gif3,
          },
          {
            id: 4,
            downloadLink: gif4,
          },
          {
            id: 5,
            downloadLink: gif5,
          },
          {
            id: 6,
            downloadLink: gif6,
          },
          {
            id: 7,
            downloadLink: gif7,
          },
          {
            id: 8,
            downloadLink: gif8,
          },
          {
            id: 9,
            downloadLink: gif9,
          },
          {
            id: 10,
            downloadLink: gif11,
          },
          {
            id: 11,
            downloadLink: gif12,
          },
        ],
      },
      {
        mId: 2,
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: contacts[7].id,
          userData: contacts[7],
          sent: true,
          received: true,
          read: true,
        },
        image: [
          {
            id: 1,
            downloadLink: img1,
          },
          {
            id: 2,
            downloadLink: img2,
          },
          {
            id: 3,
            downloadLink: img3,
          },
          {
            id: 4,
            downloadLink: img4,
          },
          {
            id: 5,
            downloadLink: img5,
          },
          {
            id: 6,
            downloadLink: img6,
          },
          {
            id: 7,
            downloadLink: img7,
          },
        ],
      },
      {
        mId: 3,
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: contacts[7].id,
          userData: contacts[7],
          sent: true,
          received: true,
          read: true,
        },
        image: [
          {
            id: 1,
            downloadLink: sticker1,
          },
          {
            id: 2,
            downloadLink: sticker2,
          },
          {
            id: 3,
            downloadLink: sticker3,
          },
          {
            id: 4,
            downloadLink: sticker4,
          },
          {
            id: 5,
            downloadLink: sticker5,
          },
          {
            id: 6,
            downloadLink: sticker6,
          },
          {
            id: 7,
            downloadLink: sticker7,
          },
          {
            id: 8,
            downloadLink: sticker8,
          },
          {
            id: 9,
            downloadLink: sticker9,
          },
          {
            id: 10,
            downloadLink: sticker10,
          },
          {
            id: 11,
            downloadLink: sticker11,
          },
        ],
      },
    ],
  },
  {
    conversationId: 14,
    userId: "61665bcb9a9a4q2c93rgwgd5",
    typingUser: contacts[0].id,
    isGroupConversation: true,
    messages: [
      {
        mId: 1,
        text: "Hi Cathie, need call?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: contacts[1].id,
          userData: contacts[1],
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Cathie let me know quick, need call?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: contacts[15].id,
          userData: contacts[15],
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Cathie, need call?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: contacts[18].id,
          userData: contacts[18],
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 15,
    userId: "614ecab426f59ce2863e106e",
    typingUser: "614ecab426f59ce2863e106e",
    messages: [
      {
        mId: 1,
        text: "Michael, I appreciate you taking the time to speak with me today. As you know, our Bitcoin ETF has experienced unprecedented demand, and we're facing significant liquidity challenges. We need a reliable partner to backstop the ETF with physical BTC, and MicroStrategy's position in the market could be crucial for us..",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab426f59ce2863e106e",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Cathie, it's good to hear from you, though the circumstances are less than ideal. I can only imagine the pressure you're under. MicroStrategy has certainly amassed a substantial holding of BTC, but we're not typically in the business of providing liquidity in this manner.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab426f59ce2863e106e",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "I understand that, Michael, but we're in uncharted territory. Your influence as a Bitcoin 'whale' could help stabilize the situation. We're prepared to make it worth your while. The synergy between ARKK and MicroStrategy could set a precedent for institutional Bitcoin transactions.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab426f59ce2863e106e",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Cathie, you know as well as I do that these assets are part of our treasury reserves; they're not meant to be liquid. It's a long-term play for us. We believe in Bitcoin's value proposition, and our strategy revolves around holding, not providing liquidity.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab426f59ce2863e106e",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "Michael, I'm not asking MicroStrategy to liquidate its position. Think of it as a strategic partnership. We could work out a lending structure that benefits us both. Your holdings could backstop the ETF, and in return, we offer a premium. It's a win-win.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab426f59ce2863e106e",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "You're asking us to take on a considerable amount of risk, Cathie. The regulatory landscape is a maze, and the implications of such a move could be complex. We need to think about our shareholders and the precedent this sets.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab426f59ce2863e106e",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "I wouldn't be here if it weren't critical. ARK's reputation is at stake, and the success of this ETF is pivotal for widespread crypto adoption. We both want the same thing: to see Bitcoin succeed. Your support could be a game-changer.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab426f59ce2863e106e",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 8,
        text: "I share your vision for Bitcoin, but you're asking for a lot. Let me discuss this with my team. I need to weigh out the risks and consider the potential regulatory repercussions. I can't make any promises, Cathie.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab426f59ce2863e106e",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 9,
        text: "Time isn't on our side, Michael. I trust you'll see the strategic value in this proposal. We're ready to negotiate terms that address your concerns. Let's make history.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab426f59ce2863e106e",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 10,
        text: "I'll get back to you, Cathie. But remember, MicroStrategy isn't a bank. We need to think this through carefully.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab426f59ce2863e106e",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 16,
    userId: "614ecab413673c7385945500",
    typingUser: "614ecab413673c7385945500",
    messages: [
      {
        mId: 1,
        text: "Andrew, I just wanted to say thank you for that engaging interview. Your questions were very incisive and really allowed us to delve deep into the potential of $CATY.",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab413673c7385945500",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "It was my pleasure, Cathie. I always appreciate our conversations because they're not just informative for our audience, but for me as well. I'm glad you found the interview constructive.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab413673c7385945500",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "Absolutely. In fact, I'd love to share the segment with my team and our stakeholders. Would it be possible for you to send me the video file of our interview?",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab413673c7385945500",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Of course, I'll have our media team prepare the clip for you. Give me just a moment to get that arranged.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab413673c7385945500",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "Alright, Cathie, I'm sending over the file now. You should have it in your inbox momentarily.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab413673c7385945500",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "Fantastic, thank you for the swift response.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab413673c7385945500",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab413673c7385945500",
          sent: true,
          received: true,
          read: true,
        },
        attachments: [
          {
            id: 1,
            name: "CATY-interview.mp4",
            desc: "Cathie speaking on $CATY",
            downloadLink: vid1,
          },
        ],
      },
    ],
  },
  {
    conversationId: 17,
    userId: "614ecab44fe4db874560b822",
    typingUser: "614ecab44fe4db874560b822",
    messages: [
      {
        mId: 1,
        text: "Hi Billy, thank you for joining me today. I’ve been following your work on Twitter closely, and your influence in the memecoin space is nothing short of impressive.",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab44fe4db874560b822",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Hey Cathie! It’s a real pleasure to be talking with you. I’ve been a fan of your forward-thinking investment strategies for a while now. What's up?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab44fe4db874560b822",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "I’ll get straight to it. ARK has been exploring some new frontiers, and we’re seeing a lot of potential in $CATY. We’re looking for someone with your unique talent to host Twitter Spaces and help us tap into a broader audience.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab44fe4db874560b822",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Sounds like a blast, Cathie! $CATY, huh? I can definitely bring the hype to Twitter and get the crowd roaring for $CATY. What kind of partnership were you thinking about?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab44fe4db874560b822",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "We're thinking of a series of Twitter Spaces where we can educate and inform people about the promise of $CATY, and we want that Billy Mays energy to make it engaging and memorable. We believe your style could make the complex world of finance accessible and exciting.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab44fe4db874560b822",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "I love that! Education with a side of hype – that's my specialty. But you know, my endorsement comes with a lot of pull. If we’re shooting for the moon, we’ve got to align our rockets right. What’s in it for the ‘Mays Effect’?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab44fe4db874560b822",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "We’re prepared to offer you a competitive compensation package for your efforts, and of course, you’ll have the full support of ARK’s research and marketing teams. We want this to be mutually beneficial—you grow your following, and $CATY gets the attention it deserves.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab44fe4db874560b822",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 8,
        text: "Alright, Cathie. I can work with that. But let's spice it up a bit – how about we include some live Q&A sessions, exclusive insights, and maybe even some guest appearances from other financial bigwigs? If we’re doing this, I say we go big!",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab44fe4db874560b822",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 9,
        text: "That aligns perfectly with our vision. We’d love to incorporate your ideas to make these spaces truly impactful. With your voice leading the charge, I think we can really make $CATY resonate with investors, both seasoned and new.",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab44fe4db874560b822",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 10,
        text: "Excellent, Cathie! You’ve got yourself a deal. Let’s get the details ironed out, and then... we take $CATY to the moon!",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab44fe4db874560b822",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 11,
        text: "Looking forward to it, Billy. We’ll have our team reach out to finalize everything. Here’s to a partnership that reaches beyond the stars!",
        time: new Date().toISOString(),
        meta: {
          receiver: "614ecab44fe4db874560b822",
          sender: myId,
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: 18,
    userId: "614ecab41rvjxnpm4v42bt08",
    typingUser: "614ecab41rvjxnpm4v42bt08",
    messages: [
      {
        mId: 1,
        text: "Cathie, hey, I'm glad I got a hold of you. You've probably heard about my current... situation. I've been following the market as best as I can from in here, and it's killing me to miss out on this whole Solana memecoin craze.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "Hi Sam, yes, I've been following the news. I must say, it's quite an unexpected call. How can I help you from... where you are?",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 3,
        text: "Look, I don't have much I can do from here, but I had one private key I never disclosed – it's etched in my memory. There's a significant amount of crypto in there that I want to move into something with potential... and I can't stop thinking about cat coins. I've heard about your $CATY catfolio fund.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 4,
        text: "Sam, you do realize that dealing with cryptocurrency, especially in your position, is extremely sensitive? I can't advise on this without considering the legal implications. How did you even manage to call me?",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 5,
        text: "I've got limited phone privileges. I've thought this through, Cathie. This has to be done delicately, and I trust your expertise. I'm not looking to make a splash – just trying to ride the wave from the inside, you know?",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 6,
        text: "I understand, Sam. But you need to understand that ARK operates within strict regulatory frameworks. Any transaction we undertake needs to be transparent and compliant with the law, especially with high-profile individuals like yourself.",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 7,
        text: "Cathie, I'm asking for a personal favor here. Could we handle it just between us? I've really got nothing to lose at this point.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 8,
        text: "Sam, this is highly unusual for me, and it goes against my professional practice. I have to be honest, it makes me uncomfortable even considering this.",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 9,
        text: "I understand that, Cathie. But I wouldn't ask if I weren't desperate. I've made my share of mistakes, but I’m trying to salvage what I can. This could be a lifeline.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 10,
        text: "I can hear the urgency in your voice, Sam. Alright, here's what I'll do. I'll use the private key you provide to purchase some cat coins for you. But we have to keep this low profile – strictly between you and me.",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 11,
        text: "Cathie, you don't know how much this means. I'll send you the key, and you handle the rest. I trust your judgment on the coins.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 12,
        text: "Send me the details, and we’ll move forward. But from here on out, let's be cautious in our communications.",
        time: new Date().toISOString(),
        meta: {
          sender: myId,
          receiver: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 13,
        text: "Absolutely. You won't regret this, Cathie.",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 14,
        text: "1. cat<br />2. meme<br />3. taco<br />4. yoga<br />5. quip<br />6. jinx<br />7. fuzz<br />8. zany<br />9. kiwi<br />10. pug<br />11. lol<br />12. bop",
        time: new Date().toISOString(),
        meta: {
          receiver: myId,
          sender: "614ecab41rvjxnpm4v42bt08",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
];

const onChangeConversations = (newData: ConversationTypes[]) => {
  conversations = newData;
};

export { conversations, onChangeConversations };
