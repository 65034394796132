import img1 from "../assets/images/small/img-1.jpg";
import img2 from "../assets/images/small/img-2.jpg";
import img4 from "../assets/images/small/img-4.jpg";
import avatar10 from "../assets/images/users/avatar-10.jpg";
import avatar13 from "../assets/images/users/avatar-13.jpg";
import avatar14 from "../assets/images/users/avatar-14.jpg";
import avatar15 from "../assets/images/users/avatar-15.jpg";
import avatar16 from "../assets/images/users/avatar-16.jpg";
import avatar17 from "../assets/images/users/avatar-17.jpg";
import avatar18 from "../assets/images/users/avatar-18.jpg";
import avatar19 from "../assets/images/users/avatar-19.jpg";
import avatar2 from "../assets/images/users/avatar-2.jpg";
import avatar20 from "../assets/images/users/avatar-20.jpg";
import avatar21 from "../assets/images/users/avatar-21.jpg";
import avatar22 from "../assets/images/users/avatar-22.jpg";
import avatar23 from "../assets/images/users/avatar-23.jpg";
import avatar24 from "../assets/images/users/avatar-24.jpg";
import avatar25 from "../assets/images/users/avatar-25.jpg";
import avatar3 from "../assets/images/users/avatar-3.jpg";
import avatar4 from "../assets/images/users/avatar-4.jpg";
import avatar5 from "../assets/images/users/avatar-5.jpg";
import avatar7 from "../assets/images/users/avatar-7.jpg";
import avatar8 from "../assets/images/users/avatar-8.jpg";
import avatar9 from "../assets/images/users/avatar-9.jpg";

// interfaces
import { STATUS_TYPES } from "../constants";
import { ChannelTypes } from "./chat";
import { AttachedfileTypes, MediaTypes } from "./myProfile";
export interface ContactTypes {
  id: string | number;
  firstName: string;
  lastName: string;
  profileImage?: any;
  about?: string;
  email: string;
  location: string;
  channels?: Array<ChannelTypes>;
  media?: MediaTypes;
  attachedFiles?: AttachedfileTypes;
  status?: STATUS_TYPES;
  isFavourite?: boolean;
  isArchived?: boolean;
}
let contacts: ContactTypes[] = [
  {
    id: "614ecab426f59ce2863e106e",
    firstName: "Michael",
    lastName: "Saylor",
    profileImage: avatar22,
    about: "Executive chairman and president of MicroStrategy.",
    email: "msaylor@microstrategy.com",
    location: "D.C., USA",
    status: STATUS_TYPES.AWAY,
  },
  {
    id: "614ecab4aeecaa03e8244d57",
    firstName: "Poe",
    lastName: "ETH",
    profileImage: avatar18,
    about:
      "10 - 1000x | Shit Coin Maxi | Jeet Destroyer | Shit poster | Meme king.",
    email: "poe@jeet.com",
    location: "California, USA",
    status: STATUS_TYPES.ACTIVE,
    channels: [
      {
        id: "61665bcb9a9a4q2c93rgwgd5",
        name: "Callers",
      },
    ],
    media: {
      total: 17,
      list: [
        {
          id: 1,
          url: img1,
        },
        {
          id: 2,
          url: img2,
        },
      ],
    },
    attachedFiles: {
      total: 4,
      list: [
        {
          id: 1,
          fileName: "design-phase-1-approved.pdf",
          size: "12.5 MB",
          downloadUrl: "#",
          icon: "bx bx-file",
        },

        {
          id: 4,
          fileName: "Landing-A.zip",
          size: "6.7 MB",
          downloadUrl: "#",
          icon: "bx bx-file",
        },
      ],
    },
  },
  {
    id: "614ecab44abffd0867521196",
    firstName: "Ansem",
    lastName: "❤️",
    profileImage: avatar20,
    about: "coldest nigga breathing | trade journal @conzimp",
    email: "zt@tcg.co",
    location: "California, USA",
    status: STATUS_TYPES.ACTIVE,
    channels: [
      {
        id: "61665bcb9a456823e282afa7",
        name: "CATY WUUD",
      },
    ],
    media: {
      total: 17,
      list: [
        {
          id: 1,
          url: img1,
        },

        {
          id: 4,
          url: img1,
        },
      ],
    },
    attachedFiles: {
      total: 4,
      list: [
        {
          id: 1,
          fileName: "design-phase-1-approved.pdf",
          size: "12.5 MB",
          downloadUrl: "#",
          icon: "bx bx-file",
        },
        {
          id: 2,
          fileName: "Image-1.jpg",
          size: "4.2 MB",
          downloadUrl: "#",
          icon: "bx bx-image",
        },
      ],
    },
  },
  {
    id: "614ecab463eda97c2df4fe9a",
    firstName: "Paul",
    lastName: "Wilson",
    profileImage: avatar2,
    about:
      "As bARK's dual-role Chief Financial Officer (CFO) and Chief Strategy Officer (CSO), I am responsible for steering the financial and strategic direction of the company. In my capacity as CFO, I manage critical financial functions such as reporting, treasury, accounting, tax, and budgetary and financial controls for the global bARK business. Simultaneously, as CSO, I align the firm’s financial strategy and plays a pivotal role in charting the strategic course for bARK's growth and success.",
    email: "paul.wilson@ark-invest.com",
    location: "USA",
    status: STATUS_TYPES.ACTIVE,
  },
  {
    id: "614ecab4ac946a9bdafa4e3b",
    isFavourite: true,
    firstName: "trippyfish",
    lastName: "",
    profileImage: avatar4,
    about: "Success is no accident.",
    email: "trippy@catywuud.com",
    location: "Solana Beach, CA",
    status: STATUS_TYPES.ACTIVE,
    channels: [
      {
        id: "61665bcb9a456823e282afa7",
        name: "CATY WUUD",
      },
    ],
  },
  {
    id: "614ecab41f60c4fdffe639c8",
    firstName: "Beth",
    lastName: "Bilyeu",
    profileImage: avatar3,
    about:
      "I joined bARK in October 2021. As Administrative Assistant, I manage day to day tasks for the executive team and assist any others where needed.",
    email: "beth.bilyeu@ark-invest.com",
    location: "Florida, USA",
    status: STATUS_TYPES.AWAY,
  },
  {
    id: "614ecab400931b0aba9d0d76",
    firstName: "Kyle",
    lastName: "Bodine",
    profileImage: avatar13,
    about:
      "As ARK’s Controller, I am responsible for the management of the corporate entity’s accounting records, financial statement preparation, budget and forecasting, as well as the facilitation of the company’s Sarbanes-Oxley (SOX) framework and operation. I joined bARK in June 2022.",
    email: "kyle.bodine@ark-invest.com",
    location: "Greater Tampa Bay Area, USA",
    status: STATUS_TYPES.ACTIVE,
    channels: [
      {
        id: "61665bcb9a456823e282afa7",
        name: "CATY WUUD",
      },
    ],
  },
  {
    id: "614ecab4c2a5e35f32f78659",
    firstName: "Ben",
    lastName: "Boxer",
    profileImage: avatar5,
    about:
      "I joined bARK in February 2024. As Marketing Associate, I execute on ARK’s daily content needs, helping with every aspect of production such as packaging and sharing the insights of ARK’s research team in engaging videos, generating content from podcast productions, and crafting informative stories highlighting ARK’s portfolio of innovative investments",
    email: "ben.boxer@ark-invest.com",
    location: "Colorado, USA",
    status: STATUS_TYPES.ACTIVE,
    channels: [
      {
        id: "61665bcb9a456823e282afa7",
        name: "CATY WUUD",
      },
    ],
    media: {
      total: 17,
      list: [
        {
          id: 1,
          url: img1,
        },
        {
          id: 2,
          url: img2,
        },
        {
          id: 3,
          url: img4,
        },
        {
          id: 4,
          url: img1,
        },
      ],
    },
    attachedFiles: {
      total: 4,
      list: [
        {
          id: 1,
          fileName: "design-phase-1-approved.pdf",
          size: "12.5 MB",
          downloadUrl: "#",
          icon: "bx bx-file",
        },
        {
          id: 2,
          fileName: "Image-1.jpg",
          size: "4.2 MB",
          downloadUrl: "#",
          icon: "bx bx-image",
        },
        {
          id: 3,
          fileName: "Image-2.jpg",
          size: "3.1 MB",
          downloadUrl: "#",
          icon: "bx bx-image",
        },
        {
          id: 4,
          fileName: "Landing-A.zip",
          size: "6.7 MB",
          downloadUrl: "#",
          icon: "bx bx-file",
        },
      ],
    },
  },
  {
    id: "614ecab4fd445a537e2bb2b5",
    firstName: "Frank",
    lastName: "Downing",
    profileImage: avatar14,
    about:
      "I joined bARK in April 2021. As Director of Research, I guide the research of the Next Generation Internet team. ARK’s Directors of Research recognize that disruptive innovation demands a dynamic and universal approach.",
    email: "frank.downing@ark-invest.com",
    location: "Illinois, USA",
    status: STATUS_TYPES.ACTIVE,
    channels: [
      {
        id: "61665bcb9a456823e282afa7",
        name: "CATY WUUD",
      },
    ],
  },
  {
    id: "614ecab4ebb18e740e372e80",
    firstName: "Elon",
    lastName: "Musk",
    profileImage: avatar8,
    about: "@elonmusk",
    email: "elon@x.com",
    location: "USA",
    status: STATUS_TYPES.ACTIVE,
    channels: [
      {
        id: "61665bcb9a456823e282afa7",
        name: "CATY WUUD",
      },
    ],
  },
  {
    id: "614ecab4fec7ab1ffb2a497d",
    firstName: "Donald",
    lastName: "Trump",
    profileImage: avatar21,
    about:
      "American politician, media personality, and businessman who served as the 45th president of the United States from 2017 to 2021.",
    email: "donald@trump.com",
    location: "Mar-a-Lago, Florida, USA",
    status: STATUS_TYPES.ACTIVE,
  },
  {
    id: "614ecab4363b167c147a422b",
    firstName: "Nathan",
    lastName: "Gemmell",
    profileImage: avatar15,
    about:
      "I joined bARK in October 2019. In my role as Trader, I work closely with ARK’s Operations and Portfolio Management teams to help manage ARK’s investment strategies.",
    email: "nathan.gemmell@ark-invest.com",
    location: "New York, USA",
    status: STATUS_TYPES.ACTIVE,
  },
  {
    id: "614ecab44fe4db874560b822",
    firstName: "Billy",
    lastName: "Mays",
    profileImage: avatar24,
    about:
      "Hey! I’m Billy Mays! Are you guys tired of being broke? #SpacesHost",
    email: "billy@oxiclean.com",
    location: "Pennsylvania, USA",
    status: STATUS_TYPES.ACTIVE,
    channels: [
      {
        id: "61665bcb9a456823e282afa7",
        name: "CATY WUUD",
      },
    ],
  },
  {
    id: "614ecab49b13ce36ad017914",
    firstName: "Mario",
    lastName: "Nawfal",
    profileImage: avatar7,
    about: "Unfiltered Unbiased Verified 24x7 Breaking News.",
    email: "mario@roundtable.live",
    location: "Palm Jumeirah, Dubai",
    status: STATUS_TYPES.ACTIVE,
    channels: [
      {
        id: "61665bcb9a456823e282afa7",
        name: "CATY WUUD",
      },
    ],
  },
  {
    id: "614ecab48c8cc404a0604fc4",
    firstName: "Michael",
    lastName: "Burry",
    profileImage: avatar16,
    about: "CEO, Scion Asset Management, LLC.",
    email: "michael@scionasset.com",
    location: "California, USA",
    status: STATUS_TYPES.ACTIVE,
    isArchived: true,
    channels: [
      {
        id: "61665bcb9a456823e282afa7",
        name: "CATY WUUD",
      },
    ],
  },
  {
    id: "614ecab45f5f3e45d25d5bbd",
    firstName: "Eric",
    lastName: "Cryptoman",
    profileImage: avatar17,
    about: "Shitcoin maxi",
    email: "eric.cryptoman@solana-phone.com",
    location: "Emirates Hills, Dubai",
    status: STATUS_TYPES.ACTIVE,

    channels: [
      {
        id: "61665bcb9a9a4q2c93rgwgd5",
        name: "Callers",
      },
    ],
  },
  {
    id: "614ecab4a4666b6ff5f7864f",
    firstName: "Kellen",
    lastName: "Carter",
    profileImage: avatar9,
    about:
      "I joined bARK in April 2016. As ARK’s Corporate Counsel and Chief Compliance Officer, I provide a full range of regulatory, compliance and legal advice to ARK on all business matters.",
    email: "kellen.carter@ark-invest.com",
    location: "New York, USA",
    status: STATUS_TYPES.DO_NOT_DISTURB,
  },
  {
    id: "614ecab43dbce45d6d996d9f",
    firstName: "Alexandre",
    lastName: "Bini",
    profileImage: avatar10,
    about: "Co-Founder & CTO at DEX Screener.",
    email: "alexandre@dexscreener.com",
    location: "São Paulo, Brazil",
    status: STATUS_TYPES.ACTIVE,
    channels: [
      {
        id: "61665bcb9a456823e282afa7",
        name: "CATY WUUD",
      },
    ],
  },
  {
    id: "614ecab41b1f288b60a9f5c9",
    firstName: "Dogen",
    lastName: "",
    profileImage: avatar19,
    about:
      "community maxi | everything is possible | nothing is financial advice",
    email: "hi@dogen.org",
    location: "California, USA",
    status: STATUS_TYPES.ACTIVE,

    channels: [
      {
        id: "61665bcb9a9a4q2c93rgwgd5",
        name: "Callers",
      },
    ],
  },
  {
    id: "614ecab413673c7385945500",
    firstName: "Andrew",
    lastName: "Sorkin",
    profileImage: avatar23,
    about:
      "Writer and producer, known for Billions (2016), Untitled Game Stop Project and I Am Not Satoshi.",
    email: "andrews@gmail.com",
    location: "New York, USA",
    status: STATUS_TYPES.DO_NOT_DISTURB,
  },
  {
    id: "614ecab41rvjxnpm4v42bt08",
    firstName: "Sam",
    lastName: "Bankman-Fried",
    profileImage: avatar25,
    about: "CEO of FTX Trading Ltd, Founder of Alameda Research.",
    email: "sbf@ftx.com",
    location: "California, USA",
    status: STATUS_TYPES.DO_NOT_DISTURB,
  },
];

const onChangeContacts = (newData: Array<ContactTypes>) => {
  contacts = newData;
};
export { contacts, onChangeContacts };
