// interfaces
import { contacts } from "./contacts";
import { AttachedfileTypes, MediaTypes } from "./myProfile";

// import img1 from "../assets/images/small/img-1.jpg";
// import img2 from "../assets/images/small/img-2.jpg";

// interface
import { ContactTypes } from "./contacts";
interface MemberTypes extends ContactTypes {
  isAdmin?: boolean;
}
interface ChannelDataTypes {
  id: number | string;
  name: string;
  about?: string;
  members: Array<MemberTypes>;
  media?: MediaTypes;
  attachedFiles?: AttachedfileTypes;
  isArchived?: boolean;
  isFavourite?: boolean;
}
let userChannels: ChannelDataTypes[] = [
  {
    id: "61665bcb9a456823e282afa7",
    name: "CATY WUUD",
    about: "$CATY WUUD Links & Socials.",
    isFavourite: true,
    members: [
      { ...contacts[2] },
      { ...contacts[4] },
      { ...contacts[6] },
      { ...contacts[7] },
      { ...contacts[8] },
      { ...contacts[9] },
      { ...contacts[12] },
      { ...contacts[13] },
      { ...contacts[17] },
    ],
  },
  {
    id: "61665bcbc0f86d33164365c8",
    name: "Memes",
    members: [{ ...contacts[0] }, { ...contacts[1] }],
  },
  {
    id: "61665bcb9a9a4q2c93rgwgd5",
    name: "Callers",
    members: [{ ...contacts[15] }, { ...contacts[1] }, { ...contacts[18] }],
  },
];

const onChangeUserChannels = (newData: Array<ChannelDataTypes>) => {
  userChannels = newData;
};

export { onChangeUserChannels, userChannels };
